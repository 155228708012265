<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="时间段" class="ui-form__item">
						<a-range-picker v-model:value="time" :disabledDate="disabledDate"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
                      <ExportReport type="financeDayRevenueStatementExportStrategy" :searchData="searchData"></ExportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

      <!--日期展示等-->
      <div class="ui-form__report" style="margin-top: 20px"></div>

      <!--综合表-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.summary.columns" :pagination="false">
              <template #title>{{ columns.summary.header }}</template>
        </a-table>
      </div>

      <!--电影票务部分-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.film.columns" :pagination="false">
              <template #title>{{ columns.film.header }}</template>
        </a-table>
      </div>

      <!--卖品部分-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.snack.columns" :pagination="false">
              <template #title>{{ columns.snack.header }}</template>
        </a-table>
      </div>

      <!--商品-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.goods.columns" :pagination="false">
              <template #title>{{ columns.goods.header }}</template>
        </a-table>
      </div>

      <!--演出-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.perform.columns" :pagination="false">
              <template #title>{{ columns.perform.header }}</template>
        </a-table>
      </div>

      <!--卡销售-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.card.columns" :pagination="false">
              <template #title>{{ columns.card.header }}</template>
        </a-table>
      </div>

      <!--卡充值-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.cardRecharge.columns" :pagination="false">
          <template #title>{{ columns.cardRecharge.header }}</template>
        </a-table>
      </div>

      <!--礼包-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.couponPacket.columns" :pagination="false">
              <template #title>{{ columns.couponPacket.header }}</template>
        </a-table>
      </div>

      <!--特色会员-->
      <div class="ui-form__report">
        <a-table :dataSource="list" bordered :columns="columns.member.columns" :pagination="false">
              <template #title>{{ columns.member.header }}</template>
        </a-table>
      </div>

<!--			<div style="margin-top: 20px;">
				<cTable ref="cTable" :isPage="false" :searchData="searchData" :isRequest="true" :requestFun="getfinanceDayRevenueStatementList" :columns="columns" rowKey="cinemaId"
					:scroll="{ x: 7000 }">
					<template #bodyCell="{ column, record }">
					</template>
					<template ></template>
				</cTable>
			</div>-->
		</a-spin>

	</div>
</template>

<script>
    import ExportReport from '@/components/exportReport/exportReport.vue';
	import cTable from '@/components/cTable/index.vue';
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	import {
		getfinanceDayRevenueStatementList
	} from "@/service/modules/report";
	export default {
		components: {
		    ExportReport,
			cTable
		},
		data() {
			return {
				showSendModal: false,
				showRejectModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				cinemaAllList: [],
				searchData: {},
				modelRef: {},
				time: [],
				formState: {},
        columns: {
          summary: {
            header: '财务报表（综合）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '收入合计',
                dataIndex: 'totalIncome',
                width: 120,
              }, {
                title: '退款合计',
                dataIndex: 'totalOutcome',
                width: 120,
              }, {
                title: '交易金额合计',
                dataIndex: 'totalAmount',
                width: 120,
              }, {
                title: '微信总手续费',
                dataIndex: 'totalWechatFeeAmount',
                width: 120,
              }, {
                title: '总佣金',
                dataIndex: 'totalServiceFeeAmount',
                width: 120,
              }, {
                title: '预计到账',
                dataIndex: 'totalExceptionWechatIncome',
                width: 120,
              }, {
                title: '商家实收',
                dataIndex: 'shopIncome',
                width: 120,
              }
            ]
          },
          film: {
            header: '财务报表（电影票务部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '影票销售金额',
                dataIndex: 'cinemaTotalAmount',
                width: 120,
              }, {
                title: '影票微信手续费',
                dataIndex: 'cinemaWechatFeeAmount',
                width: 200,
              }, {
                title: '影票佣金',
                dataIndex: 'cinemaServiceFeeAmount',
                width: 120,
              }, {
                title: '影票退款金额',
                dataIndex: 'cinemaRefundTotalAmount',
                width: 120,
              }, {
                title: '影票退款微信手续费',
                dataIndex: 'cinemaRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '影票退款佣金',
                dataIndex: 'cinemaRefundServiceFeeAmount',
                width: 120,
              }, {
                title: '影票改签金额',
                dataIndex: 'cinemaChargeAmount',
                width: 120,
              }, {
                    title: '影票改签微信手续费',
                    dataIndex: 'cinemaChargeWechatFeeAmount',
                    width: 120,
              },{
                title: '影票改签退款金额',
                dataIndex: 'cinemaChargeRefundAmount',
                width: 120,
              }, {
                title: '影票改签退款微信手续费',
                dataIndex: 'cinemaChargeRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '影票收入合计',
                dataIndex: 'cinemaTotalIncome',
                width: 120,
              }, {
                title: '影票退款合计',
                dataIndex: 'cinemaTotalOutcome',
                width: 120,
              }, {
                title: '影票微信总手续费',
                dataIndex: 'cinemaTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '影票总佣金',
                dataIndex: 'cinemaTotalServiceFeeAmount',
                width: 120,
              }, {
                title: '影票预计到账',
                dataIndex: 'cinemaTotalExceptionWechatIncome',
                width: 120,
              }, {
                title: '影票商家实收',
                dataIndex: 'cinemaShopIncome',
                width: 120,
              },
            ]
          },
          snack: {
            header: '财务报表（卖品部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '卖品销售金额',
                dataIndex: 'snackGoodsTotalAmount',
                width: 120,
              }, {
                title: '卖品微信手续费',
                dataIndex: 'snackGoodsWechatFeeAmount',
                width: 120,
              }, {
                title: '卖品佣金',
                dataIndex: 'snackGoodsServiceFeeAmount',
                width: 120,
              },
              // {
              //   title: '卖品预计收入',
              //   dataIndex: 'snackGoodsExceptionWechatFeeAmount',
              //   width: 120,
              // },
              {
                title: '卖品退款金额',
                dataIndex: 'snackGoodsRefundTotalAmount',
                width: 120,
              }, {
                title: '卖品退款微信手续费',
                dataIndex: 'snackGoodsRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '卖品退款佣金',
                dataIndex: 'snackGoodsRefundServiceFeeAmount',
                width: 120,
              }, {
                title: '卖品收入合计',
                dataIndex: 'snackGoodsTotalIncome',
                width: 120,
              }, {
                title: '卖品退款合计',
                dataIndex: 'snackGoodsTotalOutcome',
                width: 120,
              }, {
                title: '卖品微信总手续费',
                dataIndex: 'snackGoodsTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '卖品总佣金',
                dataIndex: 'snackGoodsTotalServiceFeeAmount',
                width: 120,
              }, {
                title: '卖品预计到账',
                dataIndex: 'snackGoodsTotalExceptionWechatIncome',
                width: 120,
              },  {
                title: '卖品商家实收',
                dataIndex: 'snackGoodsShopIncome',
                width: 120,
              },
            ]
          },
          goods: {
            header: '财务报表（商品部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '商城销售金额',
                dataIndex: 'shopGoodsTotalAmount',
                width: 120,
              }, {
                title: '商城微信手续费',
                dataIndex: 'shopGoodsWechatFeeAmount',
                width: 120,
              }, {
                title: '商城佣金',
                dataIndex: 'shopGoodsServiceFeeAmount',
                width: 120,
              },
              // {
              //   title: '商城预计收入',
              //   dataIndex: 'shopGoodsExceptionWechatFeeAmount',
              //   width: 120,
              // },
              {
                title: '商城退款金额',
                dataIndex: 'shopGoodsRefundTotalAmount',
                width: 120,
              }, {
                title: '商城退款微信手续费',
                dataIndex: 'shopGoodsRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '商城退款佣金',
                dataIndex: 'shopGoodsRefundServiceFeeAmount',
                width: 120,
              }, {
                title: '商城收入合计',
                dataIndex: 'shopGoodsTotalIncome',
                width: 120,
              }, {
                title: '商城退款合计',
                dataIndex: 'shopGoodsTotalOutcome',
                width: 120,
              },  {
                title: '商城微信总手续费',
                dataIndex: 'shopGoodsTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '商城总佣金',
                dataIndex: 'shopGoodsTotalServiceFeeAmount',
                width: 120,
              }, {
                title: '商城预计到账',
                dataIndex: 'shopGoodsTotalExceptionWechatIncome',
                width: 120,
              }, {
                title: '商城商家实收',
                dataIndex: 'shopGoodsShopIncome',
                width: 120,
              },
            ]
          },
          perform: {
            header: '财务报表（演出部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '演出销售金额',
                dataIndex: 'performTotalAmount',
                width: 120,
              }, {
                title: '演出微信手续费',
                dataIndex: 'performWechatFeeAmount',
                width: 120,
              }, {
                title: '演出佣金',
                dataIndex: 'performServiceFeeAmount',
                width: 120,
              },
              // {
              //   title: '演出预计收入',
              //   dataIndex: 'performExceptionWechatFeeAmount',
              //   width: 120,
              // },
              {
                title: '演出退款金额',
                dataIndex: 'performRefundTotalAmount',
                width: 120,
              }, {
                title: '演出退款微信手续费',
                dataIndex: 'performRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '演出退款佣金',
                dataIndex: 'performRefundServiceFeeAmount',
                width: 120,
              }, {
                title: '演出收入合计',
                dataIndex: 'performTotalIncome',
                width: 120,
              }, {
                title: '演出退款合计',
                dataIndex: 'performTotalOutcome',
                width: 120,
              }, {
                title: '演出微信总手续费（影院）',
                dataIndex: 'cinemaTotalAmount',
                width: 120,
              }, {
                title: '演出微信总手续费（平台）',
                dataIndex: 'performTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '演出总佣金',
                dataIndex: 'performTotalServiceFeeAmount',
                width: 120,
              }, {
                title: '演出预计到账',
                dataIndex: 'performTotalExceptionWechatIncome',
                width: 120,
              }, {
                title: '演出商家实收',
                dataIndex: 'performShopIncome',
                width: 120,
              },
            ]
          },
          card: {
            header: '财务报表（一卡通销售部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              },  {
                title: '一卡通销售金额',
                dataIndex: 'unionCardTotalAmount',
                width: 120,
              }, {
                title: '一卡通微信手续费',
                dataIndex: 'unionCardWechatFeeAmount',
                width: 120,
              }, {
                title: '一卡通退款金额',
                dataIndex: 'unionCardRefundTotalAmount',
                width: 120,
              }, {
                title: '一卡通退款微信手续费',
                dataIndex: 'unionCardRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '一卡通收入合计',
                dataIndex: 'unionCardTotalIncome',
                width: 120,
              }, {
                title: '一卡通退款合计',
                dataIndex: 'unionCardTotalOutcome',
                width: 120,
              }, {
                title: '一卡通微信总手续费',
                dataIndex: 'unionCardTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '一卡通预计到账',
                dataIndex: 'unionCardTotalExceptionWechatIncome',
                width: 120,
              }
            ]
          },
          cardRecharge: {
            header: '财务报表（一卡通充值部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '一卡通充值金额',
                dataIndex: 'unionCardChargeTotalAmount',
                width: 120,
              }, {
                title: '一卡通充值微信手续费',
                dataIndex: 'unionCardChargeWechatFeeAmount',
                width: 120,
              },  {
                title: '一卡通充值退款金额',
                dataIndex: 'unionCardChargeRefundTotalAmount',
                width: 120,
              }, {
                title: '一卡通充值退款微信手续费',
                dataIndex: 'unionCardChargeRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '一卡通充值收入合计',
                dataIndex: 'unionCardChargeTotalIncome',
                width: 120,
              }, {
                title: '一卡通充值退款合计',
                dataIndex: 'unionCardChargeTotalOutcome',
                width: 120,
              }, {
                title: '一卡通充值微信总手续费',
                dataIndex: 'unionCardChargeTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '一卡通充值预计到账',
                dataIndex: 'unionCardChargeTotalExceptionWechatIncome',
                width: 120,
              },
            ]
          },
          couponPacket: {
            header: '财务报表（礼包部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              },{
                title: '券包销售金额',
                dataIndex: 'couponPacketTotalAmount',
                width: 120,
              }, {
                title: '券包微信手续费',
                dataIndex: 'couponPacketWechatFeeAmount',
                width: 120,
              },{
                title: '券包退款金额',
                dataIndex: 'couponPacketRefundTotalAmount',
                width: 120,
              }, {
                title: '券包退款微信手续费',
                dataIndex: 'couponPacketRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '券包收入合计',
                dataIndex: 'couponPacketTotalIncome',
                width: 120,
              }, {
                title: '券包退款合计',
                dataIndex: 'couponPacketTotalOutcome',
                width: 120,
              },  {
                title: '券包微信总手续费',
                dataIndex: 'couponPacketTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '券包预计到账',
                dataIndex: 'couponPacketTotalExceptionWechatIncome',
                width: 120,
              },
            ]
          },
          member: {
            header: '财务报表（特色会员部分）',
            columns: [
              {
                title: '所属影院',
                dataIndex: 'cinemaName',
                width: 120,
              }, {
                title: '特色会员销售金额',
                dataIndex: 'specialMemberTotalAmount',
                width: 120,
              }, {
                title: '特色会员微信手续费',
                dataIndex: 'specialMemberWechatFeeAmount',
                width: 120,
              }, {
                title: '特色会员退款金额',
                dataIndex: 'specialMemberRefundTotalAmount',
                width: 120,
              },  {
                title: '特色会员退款微信手续费',
                dataIndex: 'specialMemberRefundWechatFeeAmount',
                width: 120,
              }, {
                title: '特色会员收入合计',
                dataIndex: 'specialMemberTotalIncome',
                width: 120,
              }, {
                title: '特色会员退款合计',
                dataIndex: 'specialMemberTotalOutcome',
                width: 120,
              }, {
                title: '特色会员微信总手续费',
                dataIndex: 'specialMemberTotalWechatFeeAmount',
                width: 120,
              }, {
                title: '特色会员预计到账',
                dataIndex: 'specialMemberTotalExceptionWechatIncome',
                width: 120,
              }
            ]
          },
        },
				// columns: [
        //   {
				// 	title: '日期',
				// 	key: 'revenueDayStr',
				// 	width: 120,
				// }, {
				// 	title: '所属影院',
				// 	dataIndex: 'cinemaName',
        //   width: 120,
				// }, {
				// 	title: '影票销售金额',
				// 	dataIndex: 'cinemaTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '影票微信手续费',
				// 	dataIndex: 'cinemaWechatFeeAmount',
        //   width: 200,
				// }, {
				// 	title: '影票佣金',
				// 	dataIndex: 'cinemaServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票退款金额',
				// 	dataIndex: 'cinemaRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '影票退款微信手续费',
				// 	dataIndex: 'cinemaRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票退款佣金',
				// 	dataIndex: 'cinemaRefundServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票改签金额',
				// 	dataIndex: 'cinemaChargeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票改签退款金额',
				// 	dataIndex: 'cinemaChargeRefundAmount',
        //   width: 120,
				// }, {
				// 	title: '影票改签退款微信手续费',
				// 	dataIndex: 'cinemaChargeRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票收入合计',
				// 	dataIndex: 'cinemaTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '影票退款合计',
				// 	dataIndex: 'cinemaTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '影票微信总手续费',
				// 	dataIndex: 'cinemaTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票总佣金',
				// 	dataIndex: 'cinemaTotalServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '影票预计到账',
				// 	key: 'cinemaTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '影票商家实收',
				// 	dataIndex: 'cinemaShopIncome',
        //   width: 120,
				// },  {
				// 	title: '商城销售金额',
				// 	dataIndex: 'shopGoodsTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '商城微信手续费',
				// 	dataIndex: 'shopGoodsWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城佣金',
				// 	dataIndex: 'shopGoodsServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城预计收入',
				// 	dataIndex: 'shopGoodsExceptionWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城退款金额',
				// 	dataIndex: 'shopGoodsRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '商城退款微信手续费',
				// 	dataIndex: 'shopGoodsRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城退款佣金',
				// 	dataIndex: 'shopGoodsRefundServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城收入合计',
				// 	key: 'shopGoodsTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '商城退款合计',
				// 	dataIndex: 'shopGoodsTotalOutcome',
        //   width: 120,
				// },  {
				// 	title: '商城微信总手续费',
				// 	dataIndex: 'shopGoodsTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城总佣金',
				// 	dataIndex: 'shopGoodsTotalServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '商城预计到账',
				// 	dataIndex: 'shopGoodsTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '商城商家实收',
				// 	dataIndex: 'shopGoodsShopIncome',
        //   width: 120,
				// }, {
				// 	title: '卖品销售金额',
				// 	dataIndex: 'snackGoodsTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品微信手续费',
				// 	dataIndex: 'snackGoodsWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品佣金',
				// 	dataIndex: 'snackGoodsServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品预计收入',
				// 	dataIndex: 'snackGoodsExceptionWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品退款金额',
				// 	dataIndex: 'snackGoodsRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品退款微信手续费',
				// 	dataIndex: 'snackGoodsRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品退款佣金',
				// 	dataIndex: 'snackGoodsRefundServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品收入合计',
				// 	dataIndex: 'snackGoodsTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '卖品退款合计',
				// 	dataIndex: 'snackGoodsTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '卖品微信总手续费',
				// 	dataIndex: 'snackGoodsTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品总佣金',
				// 	key: 'snackGoodsTotalServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '卖品预计到账',
				// 	dataIndex: 'snackGoodsTotalExceptionWechatIncome',
        //   width: 120,
				// },  {
				// 	title: '卖品商家实收',
				// 	dataIndex: 'snackGoodsShopIncome',
        //   width: 120,
				// }, {
				// 	title: '演出销售金额',
				// 	dataIndex: 'performTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '演出微信手续费',
				// 	dataIndex: 'performWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出佣金',
				// 	dataIndex: 'performServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出预计收入',
				// 	dataIndex: 'performExceptionWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出退款金额',
				// 	dataIndex: 'performRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '演出退款微信手续费',
				// 	dataIndex: 'performRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出退款佣金',
				// 	key: 'performRefundServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出收入合计',
				// 	dataIndex: 'performTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '演出退款合计',
				// 	dataIndex: 'performTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '演出微信总手续费',
				// 	dataIndex: 'cinemaTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '演出微信总手续费',
				// 	dataIndex: 'performTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出总佣金',
				// 	dataIndex: 'performTotalServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '演出预计到账',
				// 	dataIndex: 'performTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '演出商家实收',
				// 	dataIndex: 'performShopIncome',
        //   width: 120,
				// }, {
				// 	title: '一卡通销售金额',
				// 	dataIndex: 'unionCardTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通微信手续费',
				// 	dataIndex: 'unionCardWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通退款金额',
				// 	dataIndex: 'unionCardRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通退款微信手续费',
				// 	dataIndex: 'unionCardRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通收入合计',
				// 	dataIndex: 'unionCardTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '一卡通退款合计',
				// 	dataIndex: 'unionCardTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '一卡通微信总手续费',
				// 	dataIndex: 'unionCardTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通预计到账',
				// 	dataIndex: 'unionCardTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值金额',
				// 	key: 'unionCardChargeTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值微信手续费',
				// 	dataIndex: 'unionCardChargeWechatFeeAmount',
        //   width: 120,
				// },  {
				// 	title: '一卡通充值退款金额',
				// 	dataIndex: 'unionCardChargeRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值退款微信手续费',
				// 	dataIndex: 'unionCardChargeRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值收入合计',
				// 	dataIndex: 'unionCardChargeTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值退款合计',
				// 	dataIndex: 'unionCardChargeTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值微信总手续费',
				// 	dataIndex: 'unionCardChargeTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '一卡通充值预计到账',
				// 	dataIndex: 'unionCardChargeTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '特色会员销售金额',
				// 	dataIndex: 'specialMemberTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '特色会员微信手续费',
				// 	key: 'specialMemberWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '特色会员退款金额',
				// 	dataIndex: 'specialMemberRefundTotalAmount',
        //   width: 120,
				// },  {
				// 	title: '特色会员退款微信手续费',
				// 	dataIndex: 'specialMemberRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '特色会员收入合计',
				// 	dataIndex: 'specialMemberTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '特色会员退款合计',
				// 	dataIndex: 'specialMemberTotalOutcome',
        //   width: 120,
				// }, {
				// 	title: '特色会员微信总手续费',
				// 	dataIndex: 'specialMemberTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '特色会员预计到账',
				// 	dataIndex: 'specialMemberTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '券包销售金额',
				// 	dataIndex: 'couponPacketTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '券包微信手续费',
				// 	dataIndex: 'couponPacketWechatFeeAmount',
        //   width: 120,
				// },{
				// 	title: '券包退款金额',
				// 	dataIndex: 'couponPacketRefundTotalAmount',
        //   width: 120,
				// }, {
				// 	title: '券包退款微信手续费',
				// 	dataIndex: 'couponPacketRefundWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '券包收入合计',
				// 	key: 'couponPacketTotalIncome',
        //   width: 120,
				// }, {
				// 	title: '券包退款合计',
				// 	dataIndex: 'couponPacketTotalOutcome',
        //   width: 120,
				// },  {
				// 	title: '券包微信总手续费',
				// 	dataIndex: 'couponPacketTotalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '券包预计到账',
				// 	dataIndex: 'couponPacketTotalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '收入合计',
				// 	dataIndex: 'totalIncome',
        //   width: 120,
				// }, {
				// 	title: '退款合计',
				// 	dataIndex: 'totalOutcome',
        //   width: 120,
				// }, {
				// 	title: '交易金额合计',
				// 	dataIndex: 'totalAmount',
        //   width: 120,
				// }, {
				// 	title: '微信总手续费',
				// 	dataIndex: 'totalWechatFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '总佣金',
				// 	dataIndex: 'totalServiceFeeAmount',
        //   width: 120,
				// }, {
				// 	title: '预计到账',
				// 	key: 'totalExceptionWechatIncome',
        //   width: 120,
				// }, {
				// 	title: '商家实收',
				// 	dataIndex: 'shopIncome',
        //   width: 120,
				// }
        // ],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
			this.getAllCinemaList();
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getfinanceDayRevenueStatementList: getfinanceDayRevenueStatementList,
			reset() {
				this.time = [this.moment().subtract(1, 'day').startOf(), this.moment().subtract(1, 'day').endOf()];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.revenueStartDay = this.time[0].startOf('days').unix();
					this.searchData.revenueEndDay = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				// this.$refs.cTable.toQuery();
				// return;
				this.loading = true;
				try {
					let ret = await getfinanceDayRevenueStatementList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({});
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			disabledDate(current) {
			    return current && current > this.moment().endOf('day');
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
  .ui-form__report {
      margin-bottom: 20px;
  }
</style>
